/*
 * Global variables
 */
$image-theme-path                : '../image/' !default;
$image-theme-skin                : '../image/' !default;
$theme-font-path                 : '../fonts/' !default;
$fonts-theme-path                : '../fonts/' !default;
// standard colors
$white		    : #FFF !default;
$black			: #000 !default;
$nocolor		: transparent !default;
$icon-font-name :  "FontAwesome" !default;

/* Fonts */
$font-icon: $icon-font-name !default;

// color for default theme
$primary  : $brand-primary !default;
$success  : $brand-success !default; 
$warning  : $brand-warning !default;
$info     : $brand-info !default;
$danger   : $brand-danger !default;

$body-color : $text-color !default; 

$theme-color    : $brand-primary !default; /* main color which will be used for all main block styles... */
$border-color	: #f2f2f2 !default;

$theme-color-default             : $brand-primary !default;
$theme-color-second  			 : #111111 !default;
$theme-color-third  			 : #666666 !default;
$theme-color-four  			     : #999999 !default;

// Theme Margin, Padding
// -------------------------
$theme-margin    : 20px !default;
$theme-padding   : 20px !default;
// fonts
$font-family-default: $font-family-base !default;
$font-family-third  : 'PT Serif', cursive !default;
$font-family-second :  $font-family-base !default;
$theme-padding      : 20px !default;
/**
 * Import component variables
 */
@import "vars/elements";
@import "vars/product";
@import "vars/layout";
@import "vars/modules";
